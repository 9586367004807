import React, { useState, useEffect } from 'react';
import get from "lodash/get";
import ItemOrderRequest from '../components/ItemOrderRequest';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import MessageOrderRequest from '../components/MessageOrderRequest';

const useStyles = makeStyles(theme => ({
  page: {
    paddingLeft: '55px',
    paddingRight: '55px',
  },
  additionalData: {
    marginTop: '67px',
    color: '#FFFFFF',
  },
  currentData: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
  },
  labelMessage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  categories: {
    maxWidth: '550px',
    minWidth: '400px',
  },
  headerCell: {
    opacity: '0.56',
  },
  viewedOffer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    color: '#FFFFFF',
    marginBottom: '10px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '67px',
    marginBottom: '36px',
    height: '67px',
    fontSize: '30px',
    color: '#FFFFFF',
  },
  container: {
    marginTop: '67px',
  }
}));

function OrderRequest(props) {

  const [orderRequest, setOrderRequest] = useState({});
  const token = localStorage.getItem('token');
  const companyId = localStorage.getItem("companyId");
  const classes = useStyles();

  useEffect(() => {
    const orderRequestId = get(props, 'match.params.orderRequestId', null);
    const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/orderRequest/${orderRequestId}/detail`;
    axios.get(url, {headers: { 'x-access-token': token}}).then((res) => {
      if (!res.data.error) {
        setOrderRequest(res.data);
      }
    });    
  }, []);

  return (
    <div className={classes.page}>
      <div className={classes.additionalData}>
        <div className={classes.currentData}>
          <div>
            Created:
          </div>
          <div style={{ marginLeft: '10px'}}>
            {orderRequest.created}
          </div>
        </div>
        <div className={classes.currentData}>
          <div>
            Buyer Complete:
          </div>
          <div style={{ marginLeft: '10px'}}>
            {orderRequest.buyerComplete}
          </div>
        </div>
        <div className={classes.currentData}>
          <div>
            Buyer Confirm:
          </div>
          <div style={{ marginLeft: '10px'}}>
            {orderRequest.buyerConfirm}
          </div>
        </div>
        <div className={classes.currentData}>
          <div>
            Buyer Name:
          </div>
          <div style={{ marginLeft: '10px'}}>
            {orderRequest.buyerName}
          </div>
        </div>
        <div className={classes.currentData}>
          <div>
            Description:
          </div>
          <div style={{ marginLeft: '10px'}}>
            {orderRequest.description}
          </div>
        </div>
        <div className={classes.currentData}>
          <div>
            Seller Complete:
          </div>
          <div style={{ marginLeft: '10px'}}>
            {orderRequest.sellerComplete}
          </div>
        </div>
        <div className={classes.currentData}>
          <div>
            Seller Confirm:
          </div>
          <div style={{ marginLeft: '10px'}}>
            {orderRequest.sellerConfirm}
          </div>
        </div>
        <div className={classes.currentData}>
          <div>
            Seller Name:
          </div>
          <div style={{ marginLeft: '10px'}}>
            {orderRequest.sellerName}
          </div>
        </div>
        <div className={classes.currentData}>
          Items:
        </div>
        <div>
          {(orderRequest.items || []).map((or, i) => (
            <div key={`o-r-${i}`}>
              <ItemOrderRequest
                item={{...or}}
              />
            </div>
          ))}
        </div>
        <div className={classes.labelMessage}>
          Messages:
        </div>
        {(orderRequest.messsages || []).map((m, i) => (
            <div key={`m-o-r-${i}`}>
              <MessageOrderRequest
                message={{...m}}
              />
            </div>
          ))}
      </div>

    </div>
  );
}


export default OrderRequest;