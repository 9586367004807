import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import { withRouter } from 'react-router-dom';
import * as firebase from 'firebase/app';
import classNames from 'classnames';
import app from '../firebase/base';
import toast from "../global/alert";
import axios from 'axios';
import logo from '../img/Hivexchange_logo.png';

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	socialMediaButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
	},
	facebookButton: {
		backgroundColor: '#4C69BA',
	},
	googleButton: {
		backgroundColor: '#DD4B39',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

function Login(props) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const classes = useStyles();

	// const handleSubmit = async (e) => {
	// 	e.preventDefault();

	// 	try {
	// 		await app
	// 				.auth()
	// 				.signInWithEmailAndPassword(email, password);
	// 		props.history.push('/');
	// 	} catch(error) {
	// 		toast.error(error.message, {
	// 			position: toast.POSITION.TOP_RIGHT
	// 		});
	// 	}
	// }

	const handleSubmit = async (e) => {
    e.preventDefault();
		const urlServer = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/user/hiveTokenLogin`;
		const res = await axios.post(urlServer, `email=${email}&password=${password}`);

		if (res && !res.data.error) {
			await localStorage.setItem('token', res.data.token);
			props.history.push('/');
		} else {
			toast.error("Incorrect email or password.", {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}

	const checkFirebaseToken = async function(firebaseToken){
		const urlServer = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/user/firebaseTokenCheck/${firebaseToken}`;
    const res = await axios.get(urlServer);
    
    if (!res.data.error ){
			await localStorage.setItem('token', res.data.token);
			props.history.push('/');
    } else {
      console.log("error logging into API");
      toast.error(res.data.reason, {
				position: toast.POSITION.TOP_RIGHT
			});
    }
	}

	const handleFacebookLogin = function(){
		let provider = new firebase.auth.FacebookAuthProvider();
		app.auth()
		.signInWithPopup(provider)
		.then(function(result) {
			// This gives you a Facebook Access Token. You can use it to access the Facebook API.
			var token = result.credential.accessToken;
			// The signed-in user info.
			var user = result.user;
			console.log("user");
			console.log(user);

			user.getIdToken().then(function(token){
				checkFirebaseToken(token);
			});
			// ...
		})
		.catch(function(error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			// The email of the user's account used.
			var email = error.email;
			// The firebase.auth.AuthCredential type that was used.
			var credential = error.credential;
			// ...

			console.log("Error")
			console.log(`errorCode: ${errorCode}`)
      console.log(`errorMessage: ${errorMessage}`);
      const message = errorMessage.split(':')[0];
      toast.error(message, {
				position: toast.POSITION.TOP_RIGHT
			});
		});
	}

	const handleGoogleLogin = function(){
		let provider = new firebase.auth.GoogleAuthProvider();
		provider.addScope('profile');
		provider.addScope('email');
		firebase.auth().signInWithPopup(provider).then(function(result) {

			// This gives you a Google Access Token.
			let token = result.credential.accessToken;
			// The signed-in user info.
			let user = result.user;
			console.log("user");
			console.log(user);

			user.getIdToken().then(function(token){
				checkFirebaseToken(token);
			});
			
		})
		.catch(function(error){
			console.log(`error logging in with Google - handleGoogleLogin()`)
      console.log( error );
      toast.error(error.message, {
				position: toast.POSITION.TOP_RIGHT
			});
		});
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<div className={classes.avatar}>
					<img src={logo} />
				</div>
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<form className={classes.form} noValidate onSubmit={handleSubmit}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						value={password}
						onChange={e => setPassword(e.target.value)}
					/>
					<FormControlLabel
						control={<Checkbox value="remember" color="primary" />}
						label="Remember me"
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Sign In
					</Button>
					<div style={{marginBottom: '16px'}}>
						<Button
							onClick={handleFacebookLogin}
							className={classNames(classes.socialMediaButton, classes.facebookButton)}
						>
							<FacebookIcon />
							<p style={{margin: 0, paddingLeft: '5px'}}>Login with Facebook</p>
						</Button>
					</div>
					<div style={{marginBottom: '16px'}}>
						<Button
							onClick={handleGoogleLogin}
							className={classNames(classes.socialMediaButton, classes.googleButton)}
						>
							<EmailIcon />
							<p style={{margin: 0, paddingLeft: '5px'}}>Login with Google</p>
						</Button>
					</div>
					<Grid container>
						<Grid item xs>
							<Link href="#" variant="body2">
								Forgot password?
							</Link>
						</Grid>
						<Grid item>
							<Link href="#" variant="body2">
								{"Don't have an account? Sign Up"}
							</Link>
						</Grid>
					</Grid>
				</form>
			</div>
		</Container>
	);
}

export default withRouter(Login);
