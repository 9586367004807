import React, { useState, useEffect } from "react";
import get from 'lodash/get';
import moment from 'moment';
import Chart from 'react-apexcharts';

function DailyUsageChart(props) {

  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
   
  useEffect(() => {
    let x_axis = [];
    const lengthData = get(props, 'data.length', 0);
    for (let i = 0; i < lengthData; i += 1) {
      const formatDay = moment(props.data[i].accessDay).format('DD MMM YY')
      x_axis.push(formatDay);
    } 
    setXaxis(x_axis);
  }, [props.data]);

  useEffect(() => {
    let y_axis = [];
    const lengthData = get(props, 'data.length', 0);
    for (let i = 0; i < lengthData; i += 1) {
      y_axis.push(props.data[i].userCount);
    }
    
    setYaxis(y_axis);
  }, [props.data]);

  const options = {
    series: [{
      data: yaxis,
    }],
    title: {
      text: 'Daily Usage',
    },
    colors: ['#56AB5A'],
    xaxis: {
      type: 'category',
      categories: xaxis,
    },
    yaxis: [{
      decimalsInFloat: 0,
    }],
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: val => val === 0 ? '' : val,
      offsetY: -18,
      style: {
        fontSize: '12px',
      }
    },
    theme: {
      mode: 'dark',
    },
  };

  return (
    <Chart
      options={options}
      series={options.series}
      type="line"
      width={'100%'}
      height={400}
    />
  );
}

export default DailyUsageChart;
