import React, {useState, useEffect, Fragment} from 'react';
import {
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as FileSaver from 'file-saver';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import GetAppIcon from '@material-ui/icons/GetApp';
import TablePaginationActions from '../components/TablePaginationActions';
import moment from 'moment';

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  pageContainer: {
    paddingLeft: '55px',
    paddingRight: '55px',
  },
  titleTable: {
    fontWeight: 'bold',
    fontSize: '40px',
    paddingTop: '15px',
    paddingBottom: '15px',
    color: '#FFFFFF',
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
    marginBottom: '8px',
  },
  dateText: {
    fontSize: '15px',
    fontWeight: 'bold',
    paddingRight: '25px',
    color: '#FFFFFF',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subMenu: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderBottom: 'none',
  }
}));

const months = ['Jan', 'Feb', 'Mar','Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const CustomSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: '#03DAC5',
    },
    '&$checked + $track': {
      backgroundColor: '#03DAC5',
    },
  },
  checked: {},
  track: {},
})(Switch);

function Inventory(props) {
  const [selectedDate, setSelectedDate] = useState(new Date('01-01-2020'));
  const [inventory, setInventory] = useState({});
  const [isOutstandingEnabled, setIsOutstandingEnabled] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem('token');
  const outstandingBalances = inventory.outStandingBalances || {};
  const stockList = inventory.stockList || [];
  const inventoryStockList = isOutstandingEnabled ? stockList.filter(
                              s => outstandingBalances[s['xero_code']])
                            : stockList;  
  const inventoryList = inventoryStockList.length > 0 ? 
                        inventoryStockList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : [];
  const classes = useStyles1();
  
  useEffect(() => {
    const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/stock/balanceSheet?startDate=2020-01-01`;
    axios.get(url, {headers: { 'x-access-token': token}}).then((res) => {
      setInventory(res.data);
    });    
  }, []);

  const handleChangeSwitch = event => {
    setIsOutstandingEnabled(!isOutstandingEnabled);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const startDate = moment(date).format('yyyy-MM-DD');
    const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/stock/balanceSheet?startDate=${startDate}`;
    axios.get(url, {headers: { 'x-access-token': token}}).then((res) => {
      setInventory(res.data);
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportDataCSV = () => {
    const startDate = moment(selectedDate).format('yyyy-MM-DD');
    const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/stock/balanceSheet?startDate=${startDate}&output=csv`;
    axios.get(url, {headers: { 'x-access-token': token}}).then((res) => {
      console.log('res');
      console.log(res);
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const data = new Blob([res.data], {type: fileType});
      const nameFile = 'inventory.csv';
      FileSaver.saveAs(data, nameFile);
    });
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.titleTable}>
        Inventory
      </div>
      <div className={classes.dateContainer}>
        <span className={classes.dateText}>
          Start Date
        </span>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            clearable
            value = {selectedDate}
            placeholder="Enter date"
            onChange={date => handleDateChange(date)}
            format="dd/MM/yyyy"
          />
        </MuiPickersUtilsProvider>
      </div>
      <br />
      <div className={classes.switchContainer}>
        <span className={classes.dateText}>
          Show outstanding Items Only:
        </span>
        <CustomSwitch
          checked={isOutstandingEnabled}
          onChange={handleChangeSwitch}
          classes={classes.switchElement}
        />
      </div>
      <br />
      <div className={classes.subMenu}>
        <IconButton
          aria-label="download"
          onClick={() => exportDataCSV()}
        >
          <GetAppIcon />
        </IconButton>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 50, 75, { label: 'All', value: -1}]}
        colSpan={4}
        count={inventoryStockList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      </div>

      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Xero Code</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center">Invoice Date</TableCell>
            <TableCell align="center">Invoice No</TableCell>
            <TableCell align="center">Trade Company</TableCell>
            <TableCell align="center">Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            inventoryList.map(({xero_code, quantity, invoiceDate, invoiceNo, tradeCompany,
              balance}, index) => (
                <TableRow key={`${xero_code}-${index}`}>
                  <TableCell align="center">
                    {xero_code}
                  </TableCell>
                  <TableCell align="center">
                    {quantity}
                  </TableCell>
                  <TableCell align="center">                  
                    {invoiceDate}
                  </TableCell>
                  <TableCell align="center">                  
                    {invoiceNo}
                  </TableCell>
                  <TableCell align="center">
                    {tradeCompany}
                  </TableCell>
                  <TableCell align="center">
                    {balance}
                  </TableCell>
                </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
}

export default Inventory;
