import React from 'react';

const style = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#303030',
  textAlign: "center",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%",
}

const phantom = {
  display: 'block',
  height: '60px',
  width: '100%',
}

function Footer({ children }) {
  return (
    <div style={{width: '100%'}}>
      <div style={phantom} />
      <div style={style}>
        { children }
      </div>
    </div>
  )
}

export default Footer;