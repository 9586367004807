import React, { useEffect, useState } from "react";
import get from 'lodash/get';
import app from "./base.js";
import axios from 'axios';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);

	useEffect(() => {
    app.auth().onAuthStateChanged(setCurrentUser);
    
    app.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const firebaseToken = await user.getIdToken();
        if (firebaseToken) {
          const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/user/firebaseTokenCheck/${firebaseToken}`;
          const res = await axios.get(url);
          const token = get(res, 'data.token', null);
          if (token) {
            await localStorage.setItem('token', token);
          }
          return res;
        }
			}
		});

  }, []);

	return (
		<AuthContext.Provider
			value={{
				currentUser
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
