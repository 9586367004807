import React, {useState, useEffect, Fragment} from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import logo from '../img/logo.svg';
import app from '../firebase/base';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  bannerLeft: {
    display: 'flex',
    flexOrientation: 'row',
    alignItems: 'center',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoContainer: {
    paddingLeft: '24px',
    marginRight: '30px',
  },
  logoText: {
    paddingLeft: '10px',
  },
  tabLink: {
    color: 'white',
    fontSize: '14px',
    letterSpacing: '0.75px',
    minWidth: '140px',
  },
  tabContainer: {
    float: 'left',
  },
  buttonContainer: {
    paddingRight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    float: 'right',
    minWidth: '150px',
  },
  logoutButton: {
    marginLeft: '18px',
    letterSpacing: '0.75px',
    fontSize: '16px',
  },
}));


const NavBar = ({props}) => {
  
  const [valueTab, setValueTab] = useState(0);
  const classes = useStyles();

  const handleLogout = () => {
    localStorage.removeItem('token');
    app.auth().signOut().then(() => {
      localStorage.removeItem('fToken');
    });

    props.history.push('/');
  }
  
  useEffect(() => {
    const { pathname } = props.location || {};
    let selectedTab = 0;
    switch (pathname) {
      case '/app/dashboard':
        selectedTab = 0;
        break;
      case '/app/invoices':
        selectedTab = 1;
        break;
      case '/app/bills':
        selectedTab = 2;
        break;
      case '/app/offers':
        selectedTab = 3;
        break;  
      case '/app/inventory':
        selectedTab = 4;
        break; 
      case '/app/report':
        selectedTab = 5;
        break; 
      case '/app/users':
        selectedTab = 6;
      break;          
    }
    setValueTab(selectedTab);
  }, []);

  return(
    <div className={classes.root}>
      <AppBar position="static" className={classes.toolbar}>
        <div className={classes.bannerLeft}>
        <div className={classes.logoContainer}>
          <img src={logo} />
        </div>
        <Tabs
          value={valueTab}
          className={classes.toolbar}
          onChange={(e, newValue) => setValueTab(newValue)}
          TabIndicatorProps={{style:{
            backgroundColor: '#3DEA8F',
            borderRadius: '3px 3px 0 0',
          }}}
        >
          <Tab 
            label={'DASHBOARD'} 
            className={classes.tabLink}
            component={Link}
            value={0}
            to={'/app/dashboard'}
          />
          <Tab 
            label={'INVOICES'} 
            className={classes.tabLink}
            component={Link}
            value={1}
            to={'/app/invoices'}
          />
          <Tab 
            label={'BILLS'} 
            className={classes.tabLink}
            component={Link}
            value={2}
            to={'/app/bills'}
          />
          <Tab 
            label={'OFFERS'} 
            className={classes.tabLink}
            component={Link}
            value={3}
            to={'/app/offers'}
          />
          <Tab 
            label={'INVENTORY'} 
            className={classes.tabLink}
            component={Link}
            value={4}
            to={'/app/inventory'}
          />
          <Tab 
            label={'APP LOG'} 
            className={classes.tabLink}
            component={Link}
            value={5}
            to={'/app/report'}
          />
          <Tab 
            label={'USERS'} 
            className={classes.tabLink}
            component={Link}
            value={6}
            to={'/app/users'}
          />
        </Tabs>
        </div>
        <div className={classes.buttonContainer}>
          <AccountCircleIcon
            style={{ color: '#FFFFFF' }}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleLogout()}
            className={classes.logoutButton}
          > 
            Log out
          </Button>
        </div>
      </AppBar>
    </div>
  )
}

export default withRouter(NavBar);