import React from 'react';
import Characteristics from '../components/Characteristics';

function Dashboard(props) {
  return (
    <Characteristics />
  ) 
}

export default Dashboard;
