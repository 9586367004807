import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    backgroundColor: "#424242",
    width: "100%",
    marginBottom: '20px',
  },
  containerHeader: {
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    height: "53px",
    fontSize: "20px",
    borderBottom: "1px solid #5D5D5D",
  },
  contentContHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  categoriesHeader: {
    display: "flex",
    flexWrap: 'wrap',
    alignItems: "center",
    marginLeft: "10px",
  },
  activateBtn: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "5px",
    },
    marginLeft: "10px",
  },
  titleText: {
    marginLeft: '5px',
  },
  expireText: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "5px",
    },
    marginLeft: "10px",
    fontSize: "16px",
  },
  labelInfo: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
  },
  card: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    width: "100%",
    display: "flex",
    flexDirection: "row",
    margin: "15px 12px",
  },
  category: {
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    color: "#FFFFFF",
    width: "170px",
  },
  contentCard: {
    display: "flex",
    flexDirection: "column",
  },
  contentCreated: {
    [theme.breakpoints.down("xs")]: {
      marginTop: '0',
    },
    fontWeight: 500,
    fontSize: "10px",
    letterSpacing: "1.5px",
    color: "#C9C9C9",
  },
  contentHeader: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
    fontWeight: 500,
    fontSize: "10px",
    letterSpacing: "1.5px",
    color: "#C9C9C9",
  },
  offerId: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    width: "15%",
  },
  details: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    display: "flex",
    flexWrap: "wrap",
    width: "40%",
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
  contentItem: {
    width: "25%",
  },
  contentMessage: {
    width: "50%",
  }, 
  contentBody: {
    fontSize: "14px",
    letterSpacing: "0.25px",
    color: "#FFFFFF",
    padding: "8px 12px 0 0",
  },
  imgRow: {
    display: "flex",
    alignItems: "center",
  },
  footer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "53px",
  },
  sectionOffer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      marginLeft: "13px",
    },
    marginLeft: "26px",
  },
  sectionDelete: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "13px",
    },
    marginRight: "26px",
  },
  buttonFooter: {
    width: "149px",
    fontSize: "14px",
    letterSpacing: "0.25px",
    color: "#3DEA8F",
  },
  imgWindow: {
    cursor: "pointer",
    height: "80px",
    maxWidth: "144px",
  },
  buttonCameraBg: {
    display: "flex",
    backgroundColor: "#303030",
    alignItems: "center",
    justifyContent: "center",
    height: "80px",
    width: "80px",
  },
  selBuyersBtn: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "22px",
    },
    marginBottom: "25px",
    marginLeft: "26px",
    height: "55px",
  },
  deleteIconBtn: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "6px",
      marginRight: "0",
    },
    marginLeft: "15px",
    marginRight: "15px",
  },
  directOfferContainer: {
    borderTop: "1px solid #5D5D5D",
    paddingTop: "20px",
  },
  directOffer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#FFFFFF",
    marginBottom: "8px",
  },
}));

function MessageOrderRequest({ message }) {
  
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.card}>
          <div className={classNames(classes.contentCard, classes.contentItem)}>
            <div className={classes.contentCreated}>CREATED</div>
            <div className={classes.contentBody}>{message.created}</div>
          </div>
          <div className={classNames(classes.contentCard, classes.contentItem)}>
            <div className={classes.contentHeader}>RECEIVER READ TIME</div>
            <div className={classes.contentBody}>{message.receiverReadTime}</div>
          </div>
          <div className={classNames(classes.contentCard, classes.message)}>
            <div className={classes.contentHeader}>MESSAGE</div>
            <div className={classes.contentBody}>{message.message}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageOrderRequest;