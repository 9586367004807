import React, { useState, useEffect, Fragment } from "react";
import {
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { Redirect } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import * as FileSaver from "file-saver";
import axios from "axios";
import get from "lodash/get";
import TablePaginationActions from "../components/TablePaginationActions";
import AppReportChart from "../data/AppReportChart";
import DailyUsageChart from "../data/DailyUsageChart";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  container: {
    marginTop: "20px",
  },
  pageContainer: {
    paddingLeft: "55px",
    paddingRight: "55px",
  },
  titleTable: {
    fontWeight: "bold",
    fontSize: "48px",
    marginTop: "67px",
    paddingBottom: "38px",
    color: "#FFFFFF",
  },
  headerCell: {
    opacity: "0.56",
  },
  dateContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "40px",
    color: "#FFFFFF",
    marginBottom: "40px",
  },
  reportBtn: {
    width: "220px",
    height: "55px",
    marginLeft: "40px",
  },
  filterSearch: {
    width: "210px",
    marginRight: "60px",
  },
  subMenu: {
    display: "flex",
    justifyContent: "flex-end",
  },
  pagination: {
    borderBottom: "none",
  },
  overflowTable: {
    width: "2000px",
    overflowX: "auto",
  },
  fixedTable: {
    width: "100%",
  },
}));

const messagesColumns = [
  { label: "Created", value: "created", width: "20%" },
  { label: "Note", value: "note", width: "40%" },
  { label: "From Name", value: "from_name", width: "20%" },
  { label: "To Name", value: "to_name", width: "20%" },
];

const notificationsColumns = [
  { label: "Send Time", value: "send_time", width: "17%" },
  { label: "Company Name", value: "company_name", width: "17%" },
  { label: "Notification Type", value: "notification_type", width: "17%" },
  { label: "Notification Title", value: "notification_title", width: "17%" },
  { label: "Notification Note", value: "notification_note", width: "32%" },
];

const offersColumns = [
  { label: "Created", value: "created", width: "25%" },
  { label: "Seller Name", value: "seller_name", width: "25%" },
  { label: "Category", value: "category", width: "25%" },
  { label: "Buyers", value: "buyers", width: "25%" },
];

const offersSeenColumns = [
  { label: "Buyer Name", value: "buyerName", width: "25%" },
  { label: "Category", value: "category", width: "25%" },
  { label: "Offer Seen", value: "offerSeen", width: "25%" },
  { label: "Seller Name", value: "sellerName", width: "25%" },
];

const usersColumns = [
  { label: "Email", value: "email", width: "25%" },
  { label: "Name", value: "name", width: "25%" },
  { label: "Last Access App", value: "last_app_access", width: "25%" },
  { label: "Company Name", value: "companyName", width: "25%" },
];

const orderRequestsColumns = [
  { label: "Created", value: "created", minWidth: "20%" },
  { label: "Buyer Name", value: "buyerName", minWidth: "20%" },
  { label: "Seller Name", value: "sellerName", minWidth: "20%" },
  { label: "Buyer Confirm", value: "buyerConfirm", minWidth: "20%" },
  { label: "Seller Confirm", value: "sellerConfirm", minWidth: "20%" },
];

const companyFeedPostsColumns = [
  { label: "Company Id", value: "companyId", minWidth: "25%" },
  { label: "Company Name", value: "companyName", minWidth: "25%" },
  { label: "TimeStamp", value: "timeStamp", minWidth: "25%" },
  { label: "Views", value: "views", minWidth: "25%" },
];

function Report(props) {
  const [typeReport, setTypeReport] = useState("messages");
  const [dataReport, setDataReport] = useState([]);
  const [totalDataLength, setTotalDataLenght] = useState(0);
  const [averageSeenHours, setAverageSeenHours] = useState(null);
  const [selectedReportId, setSelectedReportId] = useState(null)
  const [isRedirectReportId, setIsRedirectReportId] = useState(false);
  const [dailyUse, setDailyUse] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const token = localStorage.getItem("token");
  let columns = [];
  let labelMainButton = "";
  const classes = useStyles1();

  switch (typeReport) {
    case "messages":
      columns = messagesColumns;
      labelMainButton = "CSV MESSAGES";
      break;
    case "notifications":
      columns = notificationsColumns;
      labelMainButton = "CSV NOTIFICATIONS";
      break;
    case "offers":
      columns = offersColumns;
      labelMainButton = "CSV OFFERS";
      break;
    case "offers/seen":
      columns = offersSeenColumns;
      labelMainButton = "CSV OFFERS SEEN";
      break;
    case "orderRequest":
      columns = orderRequestsColumns;
      labelMainButton = "CSV ORDER REQUESTS";
      break;
    case "users":
      columns = usersColumns;
      labelMainButton = "CSV USERS";
      break;
    case "companyFeedPosts":
      columns = companyFeedPostsColumns;
      labelMainButton = "CSV COMPANY FEED POSTS";
      break;
    default:
      columns = [];
      labelMainButton = "";
  }

  useEffect(() => {
    let url = "";

    switch (typeReport) {
      case "users":
        url = `${
          process.env.REACT_APP_BASE_API_ENDPOINT
        }/api/v1/report/users/app?page=${page + 1}&page_size=${rowsPerPage}`;
        break;
      case "orderRequest":
        url = `${
          process.env.REACT_APP_BASE_API_ENDPOINT
        }/api/v1/orderRequest/list?page=${page + 1}&pageSize=${rowsPerPage}`;
        break;
      case "companyFeedPosts":
        url = `${
          process.env.REACT_APP_BASE_API_ENDPOINT
        }/api/v1/report/feed/posts?page=${page + 1}&pageSize=${rowsPerPage}`;
        break;
      default:
        url = `${
          process.env.REACT_APP_BASE_API_ENDPOINT
        }/api/v1/report/app/${typeReport}?page=${
          page + 1
        }&page_size=${rowsPerPage}`;
    }

    axios.get(url, { headers: { "x-access-token": token } }).then((res) => {
      const total = get(res, "data.total_rows", 0);
      let fieldList = "";

      switch (typeReport) {
        case "offers/seen":
          fieldList = "data.list";
          break;
        case "users":
          fieldList = "data.list";
          break;
        case "orderRequest":
          fieldList = "data.rows";
          break;
        default:
          fieldList = "data.orders";
      }
      const data = get(res, fieldList, []);
      setTotalDataLenght(total);
      setDataReport(data);
    });
  }, [typeReport, rowsPerPage, page]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/app/offers/averageSeenHours`;

    axios.get(url, { headers: { "x-access-token": token } }).then((res) => {
      setAverageSeenHours(res.data);
    });

    const urlDailyUse = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/users/dailyUsageCount`;

    axios
      .get(urlDailyUse, { headers: { "x-access-token": token } })
      .then((res) => {
        const sortedData = res.data.sort((a, b) => {
          return new Date(a.accessDay) - new Date(b.accessDay);
        });
        setDailyUse(sortedData);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDownloadFile = () => {
    let url = "";

    switch (typeReport) {
      case "users":
        url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/users/app?output=csv`;
        break;
      case "orderRequest":
        url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/orderRequest/list?output=csv`;
        break;
      case "companyFeedPosts":
        url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/feed/posts?output=csv`;
        break;  
      default:
        url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/app/${typeReport}?output=csv`;
    }

    axios
      .get(url, {
        headers: {
          "x-access-token": token,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const data = new Blob([res.data], { type: fileType });
        const nameFile =
          typeReport === "offers/seen"
            ? "offers-seen.csv"
            : `${typeReport}.csv`;
        FileSaver.saveAs(data, nameFile);
      })
      .then((e) => {
        console.log(e);
      });
  };

  const onReportRow = (id) => {
    if (!id) {
      return;
    }

    setSelectedReportId(id);
    setIsRedirectReportId(true);
  }

  if (isRedirectReportId) {
    return (
      <Redirect
        to={`/app/order-request/${selectedReportId}`}
      />
    );
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.dateContainer}>
        <div style={{ marginRight: 20 }}>Table</div>
        <Select
          id="select-search"
          value={typeReport}
          onChange={(e) => {
            setTypeReport(e.target.value);
          }}
          variant="outlined"
          className={classes.filterSearch}
        >
          <MenuItem value={"messages"}>
            <span>Messages</span>
          </MenuItem>
          <MenuItem value={"notifications"}>
            <span>Notifications</span>
          </MenuItem>
          <MenuItem value={"offers"}>
            <span>Offers</span>
          </MenuItem>
          <MenuItem value={"offers/seen"}>
            <span>Offers Seen</span>
          </MenuItem>
          <MenuItem value={"users"}>
            <span>Users</span>
          </MenuItem>
          <MenuItem value={"orderRequest"}>
            <span>Order Requests</span>
          </MenuItem>
          <MenuItem value={"companyFeedPosts"}>
            <span>Company Feed Posts</span>
          </MenuItem>
        </Select>
        <Button
          variant="outlined"
          className={classes.reportBtn}
          onClick={() => onDownloadFile()}
        >
          {labelMainButton}
        </Button>
      </div>
      <div className={classes.fixedTable}
        //className={
        //  typeReport === "orderRequest"
        //    ? classes.overflowTable
        //    : classes.fixedTable
        //}
      >
        <div className={classes.subMenu}>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 20, 50]}
            colSpan={4}
            count={totalDataLength}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
        <Table stickyHeader size="small" style={{ marginBottom: "20px" }}>
          <TableHead>
            <TableRow>
              {columns.map((column, ind) => (
                <TableCell
                  align="left"
                  className={classes.headerCell}
                  width={column.width}
                  key={`th-${ind}`}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataReport.map((dataReportRow, i) => (
              <TableRow
                hover
                key={`tr-r-${i}`}
                onClick={() => onReportRow(dataReportRow['orderRequestId'])}
              >
                {columns.map((column, index) => (
                  <TableCell
                    align="left"
                    key={`tc-${i}-${index}`}
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {dataReportRow[column.value]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.container}>
          <div className={classes.chartContainer}>
            <AppReportChart data={averageSeenHours} />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.chartContainer}>
            <DailyUsageChart data={dailyUse} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Report;
