import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as FileSaver from "file-saver";
import { Redirect } from "react-router-dom";
import moment from "moment";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  headerBand: {
    display: "flex",
    flexDirection: "row",
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
  },
  titleTable: {
    fontWeight: "bold",
    fontSize: "48px",
    marginTop: "67px",
    paddingBottom: "38px",
    color: "#FFFFFF",
  },
  headerCell: {
    opacity: "0.56",
  },
  dateContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    marginBottom: "40px",
  },
  refreshBtn: {
    width: "150px",
    height: "60px",
    marginBottom: "20px",
  },
  engagementBtn: {
    width: "200px",
    height: "60px",
    marginBottom: "20px",
    marginLeft: "20px",
  },
  filterSearch: {
    width: "140px",
    marginRight: "80px",
  },
  subMenu: {
    display: "flex",
    justifyContent: "flex-end",
  },
  pagination: {
    borderBottom: "none",
  },
  cTimeStamp: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    color: "#FFFFFF",
    marginTop: "10px",
    marginBottom: "20px",
  },
  valueTime: {
    marginLeft: "20px",
  },
  container: {
    width: "100%",
    overflowX: "auto",
  },
  cellTodaysPrevious: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "green",
  },
  getFormatValueEngagement: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  descriptionLine: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  descriptionLineValue: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  descriptionUpArrow: {
    color: "#00e600",
    marginRigth: "10px",
  },
  descriptionDownArrow: {
    color: "#FF0000",
    marginRigth: "10px",
  },
  descriptionTable: {
    color: "#FFFFFF",
    fontSize: "0.875em",
  },
  exampleCell: {
    marginTop: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
  },
}));

const columns = [
  {
    label: "Supplier Name",
    value: "supplierName",
    width: "28%",
    minWidth: "100px",
  },
  {
    label: "Buyers Count",
    value: "buyerCount",
    width: "12%",
    minWidth: "60px",
  },
  {
    label: "Active Buyers",
    value: "ActiveBuyers",
    width: "12%",
    minWidth: "60px",
  },
  {
    label: "Active Offers",
    value: "ActiveOffers",
    width: "12%",
    minWidth: "60px",
  },
  {
    label: "Updated Offers",
    value: "UpdatedOffers",
    width: "12%",
    minWidth: "60px",
  },
  {
    label: "Viewed Offers",
    value: "ViewedOffers",
    width: "12%",
    minWidth: "60px",
  },
  { label: "Chat Count", value: "ChatCount", width: "12%", minWidth: "60px" },
];

const description = {
  buyerCount:
    "How many buyers are in this supplier's network. This is the number of buyers who will receive an offer if the supplier sends it to All Buyers.",
  ActiveBuyers: "How many buyers associated with this supplier, used the app",
  ActiveOffers: "How many offers are currently active from this supplier",
  UpdatedOffers: "How many offers did they update",
  ViewedOffers:
    "How many offers were viewed for the FIRST time from a buyer in their network",
  ChatCount: "How many offer chat messages did they send or receive",
};

function EngagementData({ engageData, timeStamp }) {
  const [refreshedData, setRefreshedData] = useState([]);
  const [isRefreshedData, setIsRefreshedData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const [isRedirectSupplier, setIsRedirectSupplier] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isSelectedData, setIsSelectedData] = useState(false);
  const [selectedTimeStamp, setSelectedTimeStamp] = useState(null);

  const classes = useStyles1();

  const token = localStorage.getItem("token");
  const list = isRefreshedData || isSelectedData ? refreshedData : engageData;
  const ValueTimeStamp = isSelectedData ? selectedTimeStamp : timeStamp;

  const refreshDataList = () => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/engagement?justShow=false`;
    axios
      .get(url, { headers: { "x-access-token": token } })
      .then(async (res) => {
        const engageList = get(res, "data.data", []);
        await setRefreshedData(engageList);
        setIsRefreshedData(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const csvEngagementData = () => {
    const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/engagement?justShow=true&output=csv`;
    axios.get(url, { headers: { "x-access-token": token } }).then((res) => {
      const csvData = get(res, "data", []);
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const data = new Blob([csvData], { type: fileType });
      const nameFile = "engagement.csv";
      FileSaver.saveAs(data, nameFile);
    });
  };

  const onSupplierEngagement = async (supplierId) => {
    if (!supplierId) {
      return;
    }

    await setSelectedSupplierId(supplierId);
    setIsRedirectSupplier(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (!date) {
      return;
    }
    setIsRefreshedData(false);
    setIsLoading(true);
    const showDate = moment(date).format("YYYY-MM-DD");
    const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/engagement?showDate=${showDate}`;
    axios
      .get(url, { headers: { "x-access-token": token } })
      .then(async (res) => {
        const engageList = get(res, "data.data", []);
        const newTimeStamp = get(res, "data.timeStamp", "");
        await setRefreshedData(engageList);
        setSelectedTimeStamp(newTimeStamp);
        setIsSelectedData(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  if (isRedirectSupplier) {
    return <Redirect to={`/app/supplierEngagement/${selectedSupplierId}`} />;
  }

  if (isLoading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.headerBand}>
        <Button
          variant="outlined"
          className={classes.refreshBtn}
          onClick={() => refreshDataList()}
        >
          REFRESH DATA
        </Button>
        <Button
          variant="outlined"
          className={classes.engagementBtn}
          onClick={() => csvEngagementData()}
        >
          CSV Engagement
        </Button>
      </div>
      <div className={classes.dateContainer}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            clearable
            value={selectedDate}
            placeholder="Select Date"
            onChange={(date) => handleDateChange(date)}
            format="dd/MM/yyyy"
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </div>
      {!isRefreshedData && (
        <div className={classes.cTimeStamp}>
          <div>Last Updated:</div>
          <div className={classes.valueTime}>{ValueTimeStamp}</div>
        </div>
      )}
      {
        <div className={classes.descriptionTable}>
          <div>Each cell of Engagement Table is represented by:</div>
          <div className={classes.exampleCell}>
            <ArrowUpwardIcon className={classes.descriptionUpArrow} />
            <span style={{ marginLeft: "5px", marginRight: "5px" }}>or</span>
            <ArrowDownwardIcon className={classes.descriptionDownArrow} />
            <span style={{ marginLeft: "15px", marginRight: "5px" }}>28</span>
            <span style={{ color: "gray" }}>( 17 )</span>
          </div>
          <div>Where:</div>
          <ul>
            <li className={classes.descriptionLineValue}>
              {" "}
              28 : Represents today's value of the category
            </li>
            <li className={classes.descriptionLineValue}>
              {" "}
              <span style={{ color: "gray" }}>( 17 )</span>&nbsp;: Represents
              the last updated value of the category
            </li>
            <li className={classes.descriptionLine}>
              <ArrowUpwardIcon className={classes.descriptionUpArrow} />
              <div>
                There has been an increase in today's value compared with the
                last updated
              </div>
            </li>
            <li className={classes.descriptionLine}>
              <ArrowDownwardIcon className={classes.descriptionDownArrow} />
              <div>
                There has been a decrease in today's value compared with the
                last updated
              </div>
            </li>
          </ul>
        </div>
      }
      <div className={classes.container}>
        <Table
          stickyHeader
          size="small"
          style={{ minWidth: "900px", marginBottom: "20px" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column, ind) => (
                <TableCell
                  align="left"
                  className={classes.headerCell}
                  width={column.width}
                  key={`th-h-${ind}`}
                  minWidth={column.minWidth}
                >
                  <div>
                    <div>{column.label}</div>
                    {column.value !== "supplierName" && (
                      <Tooltip title={description[column.value]}>
                        <InfoIcon />
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((dataReportRow, i) => (
              <TableRow
                hover
                key={`tr-r-${i}`}
                onClick={() =>
                  onSupplierEngagement(dataReportRow["supplierId"])
                }
              >
                {columns.map((column, index) => {
                  let showIconArrow = false;
                  let isGreaterThanPrevious = false;
                  let colorValue = "#ffffff";
                  let addedMarginLeft = "25px";
                  if (
                    column.value !== "supplierName" &&
                    column.value !== "buyerCount"
                  ) {
                    const currentValue =
                      dataReportRow[`todays${column.value}`] || 0;
                    const previousValue =
                      dataReportRow[`previous${column.value}`] || 0;
                    if (
                      currentValue !== previousValue &&
                      dataReportRow["supplierName"] !== "All"
                    ) {
                      showIconArrow = true;
                      addedMarginLeft = "2px";
                      if (currentValue > previousValue) {
                        isGreaterThanPrevious = true;
                        colorValue = "#00e600";
                      } else {
                        colorValue = "#FF0000";
                      }
                    }
                  }
                  return (
                    <TableCell align="left" key={`tc-c-${i}-${index}`}>
                      {column.value === "supplierName" ||
                      column.value === "buyerCount" ? (
                        dataReportRow[column.value]
                      ) : (
                        <div className={classes.cellTodaysPrevious}>
                          <div
                            style={{ color: colorValue }}
                            className={classes.getFormatValueEngagement}
                          >
                            {showIconArrow ? (
                              isGreaterThanPrevious ? (
                                <ArrowUpwardIcon />
                              ) : (
                                <ArrowDownwardIcon />
                              )
                            ) : null}
                            <div
                              style={{
                                marginLeft: addedMarginLeft,
                              }}
                            >
                              {dataReportRow[`todays${column.value}`]}
                            </div>
                          </div>
                          <div style={{ color: "gray", marginLeft: "10px" }}>
                            ({dataReportRow[`previous${column.value}`]})
                          </div>
                        </div>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default EngagementData;
