import React, { useState, useEffect } from "react";
import Chart from 'react-apexcharts';

function AppReportChart(props) {

  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
   
  useEffect(() => {
    let x_axis = [];
    
    for (let i = 0; i < 24; i += 1) {
      x_axis.push(i);
    }
    
    setXaxis(x_axis);
  }, []);

  useEffect(() => {
    let y_axis = [];
    
    if (!props.data)
      return;
    
    for (let i = 0; i < 24; i += 1) {
      y_axis.push(props.data[i]);
    }
    
    setYaxis(y_axis);
  }, [props.data]);

  const options = {
    series: [{
      data: yaxis,
    }],
    title: {
      text: 'Average Seen Hours',
    },
    colors: ['#56AB5A'],
    xaxis: {
      type: 'category',
      categories: xaxis,
    },
    yaxis: [{
      decimalsInFloat: 0,
    }],
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: val => val === 0 ? '' : val,
      offsetY: -18,
      style: {
        fontSize: '12px',
      }
    },
    theme: {
      mode: 'dark',
    },
  };

  return (
    <Chart
      options={options}
      series={options.series}
      type="bar"
      width={'100%'}
      height={400}
    />
  );
}

export default AppReportChart;
