import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import axios from 'axios';
import get from 'lodash/get';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppReportChart from '../data/AppReportChart';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  page: {
    paddingLeft: '55px',
    paddingRight: '55px',
  },
  additionalData: {
    marginTop: '67px',
    color: '#FFFFFF',
  },
  currentData: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  categories: {
    maxWidth: '550px',
    minWidth: '400px',
  },
  headerCell: {
    opacity: '0.56',
  },
  viewedOffer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    color: '#FFFFFF',
    marginBottom: '10px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '67px',
    marginBottom: '36px',
    height: '67px',
    fontSize: '30px',
    color: '#FFFFFF',
  },
  container: {
    marginTop: '67px',
  }
}));

const columns = [
  { label: 'User Id', value: 'userId', width: '15%'},
  { label: 'Name', value: 'name', width: '25%'},
  { label: 'Last Access', value: 'last_app_access', width: '20%'},
  { label: 'Buyer Company Id', value: 'buyerCompanyId', width: '15%'},
  { label: 'Buyer Name', value: 'buyerName', width: '25%'},
];

function SupplierEngagement(props) {

  const [supplierEngageData, setSupplierEngageData] = useState(null);
  const [seenHours, setSeenHours] = useState(null);   
  const token = localStorage.getItem('token');
  const classes = useStyles(); 

  useEffect(() => {
    const supplierId = get(props, 'match.params.supplierId', null);

    if (supplierId) {
      const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/supplierEngagement/${supplierId}`;
      axios.get(url, {headers: { 'x-access-token': token}}).then((res) => {
        const data = get(res, 'data', {});
        setSupplierEngageData(data);
      });

      const urlSeenHours = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/app/offers/averageSeenHours?supplierId=${supplierId}`;
      axios.get(urlSeenHours, {headers: { 'x-access-token': token}}).then((res) => {
        const seenHoursData = get(res, 'data', null);
        setSeenHours(seenHoursData);
      });
    }    
  }, []);

  const getValueCellActiveBuyers = (objectRow, value) => {
    if (value !== 'last_app_access') {
      return objectRow[value];
    }

    const dateIso = objectRow[value];
    if (!dateIso)
      return '';
    
    const dateComponent = dateIso.split('.')[0];
    return moment(new Date(dateComponent)).format('DD-MM-YYYY HH:mm:ss');
  };

  const activeBuyers = get(supplierEngageData, 'activeBuyers', []);
  const viewedOffersCompanyList = get(supplierEngageData, 'viewedOffersCompanyList', []);
  const updatedToday = get(supplierEngageData, 'updatedToday', 0);
  const todaysViewedOffers = get(supplierEngageData, 'todaysViewedOffers', 0);
  const categoryList = get(supplierEngageData, 'categoryList', []);
  const categoryCount = get(supplierEngageData, 'categoryCount', {});

  return (
    <div className={classes.page}>
      <div className={classes.additionalData}>
        <div style={{marginBottom: '20px'}} className={classes.currentData}>
          <div>
            Updated Today:
          </div>
          <div style={{ marginLeft: '10px'}}>
            {updatedToday}
          </div>
        </div>
        <div className={classes.currentData}>
          <div>
            Today's Viewed Offers:
          </div>
          <div style={{ marginLeft: '10px'}}>
            {todaysViewedOffers}
          </div>
        </div>
        
      </div>
      <div className={classes.title} style={{ marginTop: '55px' }}>
        Active Buyers
      </div>
      <Table
        stickyHeader
        size="small"
        style={{marginBottom: '20px'}}
      >
        <TableHead>
          <TableRow>
            {
              columns.map((column, ind) => (
                <TableCell
                  align="left"
                  className={classes.headerCell}
                  width={column.width}
                  key={`th-h-${ind}`}
                >
                  {
                    column.label
                  }
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            activeBuyers.map((dataReportRow, i) => (
              <TableRow
                key={`tr-r-${i}`}
              >
                {
                  columns.map((column, index) => (
                    <TableCell
                      align="left"
                      key={`tc-c-${i}-${index}`}
                    >
                      {
                        getValueCellActiveBuyers(dataReportRow, column.value)
                      }
                    </TableCell>
                  ))
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <div className={classes.title}>
        Viewed Offers Company List
      </div>
      {
        viewedOffersCompanyList.map((vo, i) => (
          <div
            key={`v-o-c-l-${i}`}
            className={classes.viewedOffer}
          >
            <CheckIcon />
            <div style={{ marginLeft: '15px'}}>
              {vo}
            </div>
          </div>
        ))
      }
      <div className={classes.categories}>
        <div className={classes.title}>
          Categories
        </div>
        <Table
          stickyHeader
          size="small"
          style={{marginBottom: '20px'}}
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className={classes.headerCell}
                width={'75%'}
              >
                Category
              </TableCell>
              <TableCell
                align="left"
                className={classes.headerCell}
                width={'25%'}
              >
                Count
              </TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
          {
            categoryList.map((category, i) => (
              <TableRow
                key={`tr-r-c-${i}`}
              >
                <TableCell
                  align="left"
                  key={`tc-c-c-${i}`}
                >
                  {category}
                </TableCell>
                <TableCell
                  align="left"
                  key={`tc-c-cs-${i}`}
                >
                  {categoryCount[category]}
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      </div>
      {
        seenHours && (
          <Card className={classes.container}>
            <div className={classes.chartContainer}>
              <AppReportChart data={seenHours} />
            </div>
          </Card>
        )
      }
    </div>
  );
}

export default SupplierEngagement;
