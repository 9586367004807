import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import get from 'lodash/get';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import * as FileSaver from 'file-saver';

const useStyles = makeStyles(theme => ({
  page: {
    paddingLeft: '55px',
    paddingRight: '55px',
  },
  additionalData: {
    marginTop: '67px',
    color: '#FFFFFF',
  },
  currentData: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  categories: {
    maxWidth: '550px',
    minWidth: '400px',
  },
  headerCell: {
    opacity: '0.56',
  },
  viewedOffer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    color: '#FFFFFF',
    marginBottom: '10px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '48px',
    marginTop: '67px',
    paddingBottom: '38px',
    color: '#FFFFFF',
  },
  userBtn: {
    width: '180px',
    height: '55px',
    marginBottom: '40px',
  },
  appUsersBtn: {
    width: '180px',
    height: '55px',
    marginRight: '15px',
    marginBottom: '40px',  
  },
  container: {
    marginTop: '67px',
  }
}));

const columns = [
  { label: 'User Created', value: 'userCreated', width: '15%'},
  { label: 'First Name', value: 'firstname', width: '20%'},
  { label: 'Last Name', value: 'lastname', width: '20%'},
  { label: 'Phone', value: 'phone', width: '15%'},
  { label: 'Company Name', value: 'companyName', width: '30%'},
];

function Users(props) {

  const [users, setUsers] = useState([]);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [isLoadingAppCSV, setIsLoadingAppCSV] = useState(false);
  
  const token = localStorage.getItem('token');
  const classes = useStyles(); 

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/api/v1/report/users/new`;
    axios.get(url, {headers: { 'x-access-token': token}}).then((res) => {
      const data = get(res, 'data', []);
        setUsers(data);
      });
  }, []);

  const onDownloadFile = (compUrl, nameFile, isAppBtn) => {
    if (isAppBtn) {
      setIsLoadingAppCSV(true);
    } else {
      setIsLoadingCSV(true);  
    }
    const url = `${process.env.REACT_APP_BASE_API_ENDPOINT}/${compUrl}`;
    axios.get(url, { headers: { 'x-access-token': token } }).then((res) => {
      setIsLoadingAppCSV(false);
      setIsLoadingCSV(false);
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const data = new Blob([res.data], { type: fileType });
      FileSaver.saveAs(data, nameFile);
    }).catch((e) => {
      setIsLoadingAppCSV(false);
      setIsLoadingCSV(false);
      console.log(e);
    });
  }
  
  return (
    <div className={classes.page}>
      <div className={classes.title}>
        Users
      </div>
      <Button
        variant='outlined'
        className={classes.appUsersBtn}
        onClick={() => onDownloadFile('api/v1/report/users/app?output=csv', 'App-users.csv', true)}
      >
        {isLoadingAppCSV ?
          <CircularProgress
            size="20px"
          />
          :
          'CSV App Users'
        }
      </Button>
      <Button
        variant='outlined'
        className={classes.userBtn}
        onClick={() => onDownloadFile('api/v1/report/users/new?output=csv', 'New-users.csv')}
      >
        {isLoadingCSV ?
          <CircularProgress
            size="20px"
          />
          :
          'CSV New Users'
        }
      </Button>
      <Table
        stickyHeader
        size="small"
        style={{marginBottom: '20px'}}
      >
        <TableHead>
          <TableRow>
            {
              columns.map((column, ind) => (
                <TableCell
                  align="left"
                  className={classes.headerCell}
                  width={column.width}
                  key={`th-h-${ind}`}
                >
                  {
                    column.label
                  }
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            users.map((user, i) => (
              <TableRow
                key={`tr-r-${i}`}
              >
                {
                  columns.map((column, index) => (
                    <TableCell
                      align="left"
                      key={`tc-c-${i}-${index}`}
                      style={{
                        whiteSpace: "normal",
                        wordBreak: "break-word"
                      }}
                    >
                      {
                        user[column.value]
                      }
                    </TableCell>
                  ))
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
}

export default Users;
