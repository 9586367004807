import React from "react";
import Chart from 'react-apexcharts';

function DashboardChart(props) {

  const options = {
    series: props.data.series || [],
    title: {
      text: 'Cash in-out per Month',
    },
    colors: ['#29B6F6', '#56AB5A', '#FFCA28'],
    xaxis: {
      type: 'category',
      categories: props.data.categories || [],
    },
    yaxis: [{
      labels: {
        formatter: (val) => {
          if (val < 0) {
            return `-$${Math.abs(val)}`
          }
          return `$${val}`
        },
      },
      decimalsInFloat: 2
    }],
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: val => val === 0 ? '' : val,
      offsetY: -10,
      style: {
        fontSize: '6.5px',
      }
    },
    theme: {
      mode: 'dark',
    },
  };

  return (
    <Chart
      options={options}
      series={options.series}
      type="bar"
      width={'100%'}
      height={400}
    />
  );
}

export default DashboardChart;
