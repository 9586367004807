import React from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";

import AppLayout from '../../layout/AppLayout';
import Dashboard from "../../views/Dashboard";
import Invoices from "../../views/Invoices";
import Bills from "../../views/Bills";
import Offers from '../../views/Offers';
import Inventory from '../../views/Inventory';
import Report from '../../views/Report';
import SupplierEngagement from '../../views/SupplierEngagement';
import Users from '../../views/Users';
import OrderRequest from '../../views/OrderRequest';

function App(props) {
  const { match } = props;
  return (
    <AppLayout history={props.history}>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
        <Route path={`${match.url}/dashboard`} component={Dashboard} />
        <Route path={`${match.url}/invoices`} component={Invoices} />
        <Route path={`${match.url}/bills`} component={Bills} />
        <Route path={`${match.url}/offers`} component={Offers} />
        <Route path={`${match.url}/inventory`} component={Inventory} />
        <Route path={`${match.url}/report`} component={Report} />
        <Route path={`${match.url}/users`} component={Users} />
        <Route exact path={`${match.url}/supplierEngagement/:supplierId`}
          render={(props) => <SupplierEngagement {...props} /> }
        />
        <Route exact path={`${match.url}/order-request/:orderRequestId`}
          render={(props) => <OrderRequest {...props} /> }
        />
      </Switch>
    </AppLayout>
  );
}

export default withRouter(App);
