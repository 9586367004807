import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#3DEA8F',
    },
    type: 'dark',
  },
  typography: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#212121',
        height: '78px',
      }
    },
    MuiTabs: {
      flexContainer: {
        height: '78px',
      }
    },
    MuiButton: {
      outlinedPrimary: {
        color: '#FFFFFF',
        border: '1px solid #3DEA8F',
      },
      containedPrimary: {
        color: '#000000',
        backgroundColor: '#03DAC5',
      },
      containedSecondary: {
        color: "#FFFFFF",
        backgroundColor: '#424242',
        border: "1px solid #03DAC5",
      },
    },
    MuiIconButton: {
      root: {
        color: '#FFFFFF',
      }
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
      },
    },
    MuiTableCell: {
      head: {
        color: '#FFFFFF',
      },
      body: {
        color: '#FFFFFF',
      },
    },
    MuiCardHeader: {
      root: {
        padding: '20px 16px 5px 16px',
      },
      title: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '140%',
      }
    },
    MuiCardContent: {
      root: {
        padding: '0 16px 5px 16px',
      },
    },
  },
});